import { TPaginationPayload } from '../../types/api';
import { TGenerationTask } from '../../types/task';

export enum EFeedDescriptionType {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
}

type TFeedUrlDescription = {
  description: string;
  name: string;
  type: EFeedDescriptionType;
};

export type TProject = {
  createAt: string;
  feedURL: string;
  id: string;
  name: string;
  scheduleEnabled: boolean;
  statisticsEnabled?: boolean;
  updateAt: string;
  scheduledFeedURL: string | null;
  feedOfferDescriptions: TFeedUrlDescription[];
};

export type TFullProject = TProject & {
  feedOfferDescriptions: TFeedUrlDescription[];
};

export type TProjectsResponse = {
  count: number;
  projects: TProject[];
};

export type TCreateProjectForm = {
  name: string;
  feedURL: string;
  scheduleEnabled: boolean;
  statisticsEnabled?: boolean;
};

export type TPatchProjectPayload = {
  data: TCreateProjectForm;
  id: string;
};

type TBanner = {
  id: string;
  imageURL: string;
  createAt: string;
  updateAt: string;
  projectId: string;
  taskId: null;
};

export type TProjectBannersResponse = {
  data: TBanner[];
  total: number;
};

export type TProjectBannersPayload = {
  projectId?: string;
  pagination?: TPaginationPayload;
  generationId?: string;
};

export type TProjectTasksResponse = {
  generationTasks?: TGenerationTask[];
} & TFullProject;

export enum ELayerType {
  IMAGE = 'image',
  FEED_PARAM = 'feed_param',
}

export type TLayer = {
  type: ELayerType;
  content: string;
};

export type TBannerTemplate = {
  id: string;
  updateAt: string;
  createAt: string;
  height: number;
  width: number;
  frame: {
    asisFileId: string;
    asisFileLocation: string;
    id: string;
    name: string;
    type: string;
    layers: TLayer[];
  };
};

export type TProjectTemplateResponse = {
  feedURL: string;
  id: string;
  name: string;
  offersCount: number;
  ownerId: string;
  scheduleEnabled: boolean;
  scheduledFeedURL: null | string;
  updatedAt: string;
  createdAt: string;
  feedOfferDescriptions: TFeedUrlDescription[];
  bannerTemplates: TBannerTemplate[];
};

export type TStatistic = {
  totalDownloads: number,
  currentWeekDownloads: number,
  previousWeekDownloads: number,
  currentMonthDownloads: number,
  previousMonthDownloads: number,
  weekGrowthRate: number,
  monthGrowthRate: number
}

export type TStatisticBanners = {
  id: string,
  imageURL: string,
  createdAt: string,
  updatedAt: string,
  statistic: TStatistic
}

export type TStatisticsResponse = {
  total: number,
  banners: TStatisticBanners[]
}


