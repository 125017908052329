import type { RefObject } from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';

type HookResult = {
  triggerRef: RefObject<HTMLDivElement>;
};

export function useInfinityScroll(
  isFetching: boolean,
  callback: () => void,
  skip?: boolean
): HookResult {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const triggerRef = useRef<HTMLDivElement | null>(null);

  const intersectionCallback: IntersectionObserverCallback = useCallback(
    (entries) => {
      const entry = entries[0];

      if (entry.isIntersecting && !isFetching && skip !== true) {
        callback();
      }
    },
    [isFetching, skip, callback]
  );

  useEffect(() => {
    observerRef.current = new IntersectionObserver(intersectionCallback, {
      rootMargin: '200px', // Отступ от нижней границы экрана для активации
    });

    if (triggerRef.current) {
      observerRef.current.observe(triggerRef.current);
    }

    return () => {
      if (triggerRef.current && observerRef.current) {
        observerRef.current.unobserve(triggerRef.current);
      }
    };
  }, [intersectionCallback]);

  return useMemo<HookResult>(() => ({ triggerRef }), []);
}
