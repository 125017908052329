import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { authApi } from '../../../api';
import { AuthResponse, UserResponse } from '../../../api/auth/types';
import { RootState } from '../../index';

export type TState = {
  accessToken: string | null;
  refreshToken: string | null;
  userData: UserResponse | null;
  authed: boolean;
  authorizedError: boolean;
};

const initialState: TState = {
  accessToken: null,
  refreshToken: null,
  userData: null,
  authed: false,
  authorizedError: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => {
      localStorage.clear();
      return initialState;
    },
    setAuthAction: (state, action: PayloadAction<AuthResponse>) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.authed = true;
      state.authorizedError = false;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.accessToken = payload.accessToken;
        state.refreshToken = payload.refreshToken;
        state.authed = true;
        state.authorizedError = false;
      }
    );
    builder.addMatcher(
      authApi.endpoints.register.matchFulfilled,
      (state, { payload }) => {
        state.accessToken = payload.accessToken;
        state.refreshToken = payload.refreshToken;
        state.authed = true;
        state.authorizedError = false;
      }
    );
    builder.addMatcher(
      authApi.endpoints.userInfo.matchFulfilled,
      (state, { payload }) => {
        state.userData = payload;
        state.authorizedError = false;
      }
    );
  },
});

export const { logout, setAuthAction } = authSlice.actions;
export const selectAuth = (state: RootState) => state.user.auth;

export default authSlice.reducer;
