import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import ProjectBannersTab from '../containers/project/project-banners-tab';
import { ProjectGenerationLogsTab } from '../containers/project/project-generation-logs-tab';
import { ProjectStatisticTab } from '../containers/project/project-statistic-tab';
import { ESubPaths } from './route-paths';

type Props = {
  projectId?: string;
};

export const GenerationRoutes: FC<Props> = ({ projectId }) => (
  <Routes>
    <Route
      index
      element={<ProjectBannersTab projectId={projectId} hideBackButton />}
    />
    <Route
      path={ESubPaths.LOGS}
      element={
        <ProjectGenerationLogsTab projectId={projectId} hideBackButton />
      }
    />
    <Route
      path={ESubPaths.STATISTIC}
      element={<ProjectStatisticTab projectId={projectId} />}
    />
  </Routes>
);
