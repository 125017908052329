import { FC, useMemo } from 'react';

import { useGetByIdQuery } from '../../api/projects';
import OfferDescription from '../../components/ui/offer-description';
import { ProjectStatisticShort } from '../../components/ui/project-statistic/project-statistic-short';
import { mapFeedOfferDescriptionToItemsProps } from '../../utils/project';

import { DescriptionsProps } from 'antd/es/descriptions';

type TProps = {
  projectId?: string;
};

const ProjectMainInfoTab: FC<TProps> = ({ projectId }) => {
  const { isLoading, data } = useGetByIdQuery(projectId as string, {
    skip: !projectId,
  });

  const offerDescription: DescriptionsProps['items'] = useMemo(
    () =>
      mapFeedOfferDescriptionToItemsProps(data?.feedOfferDescriptions ?? []),
    [data]
  );

  return (
    <>
      <ProjectStatisticShort data={data} loading={isLoading} />
      <OfferDescription title="Описание офера" items={offerDescription} />
    </>
  );
};

export default ProjectMainInfoTab;
