import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import ProjectBannersTab from '../containers/project/project-banners-tab';
import { ProjectGenerationLogsTab } from '../containers/project/project-generation-logs-tab';
import ProjectGenerationTasksTab from '../containers/project/project-generation-tasks-tab';
import ProjectMainInfoTab from '../containers/project/project-main-info-tab';
import { ProjectStatisticTab } from '../containers/project/project-statistic-tab';
import ProjectTemplateTab from '../containers/project/project-template-tab';
import { ESubPaths } from './route-paths';

type Props = {
  projectId?: string;
};

export const ProjectRoutes: FC<Props> = ({ projectId }) => (
  <Routes>
    <Route index element={<ProjectMainInfoTab projectId={projectId} />} />
    <Route
      path={ESubPaths.GENERATION_TASKS}
      element={<ProjectGenerationTasksTab projectId={projectId} />}
    />
    <Route
      path={`${ESubPaths.BANNERS}/:generationId`}
      element={<ProjectBannersTab projectId={projectId} />}
    />
    <Route
      path={ESubPaths.STATISTIC}
      element={<ProjectStatisticTab projectId={projectId} />}
    />
    <Route
      path={`${ESubPaths.LOGS}/:generationId`}
      element={<ProjectGenerationLogsTab projectId={projectId} />}
    />
    <Route
      path={ESubPaths.LOGS}
      element={<ProjectGenerationLogsTab projectId={projectId} />}
    />
    <Route
      path={ESubPaths.BANNERS}
      element={<ProjectBannersTab projectId={projectId} />}
    />
    <Route
      path={ESubPaths.TEMPLATE}
      element={<ProjectTemplateTab projectId={projectId} />}
    />
  </Routes>
);
