import { isEqual } from 'lodash';

import {
  TGenerationLogsPayload,
  TGenerationLogsResponse,
} from '../../types/generation-logs';
import { TGenerationTask } from '../../types/task';
import { baseEmptyApi } from '../api';
import { TProjectTasksResponse } from '../projects/types';

export const generationTasksApi = baseEmptyApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getProjectGenerationTasks: builder.query<TProjectTasksResponse, string>({
      query: (projectId) => ({
        url: `/api/projects/${projectId}/generation-tasks`,
        method: 'GET',
      }),
    }),
    cancelGenerationTask: builder.mutation<TGenerationTask, string>({
      query: (taskId) => ({
        url: `api/generation-tasks/${taskId}/cancel`,
        method: 'POST',
      }),
    }),
    getTaskLogs: builder.query<TGenerationLogsResponse, TGenerationLogsPayload>(
      {
        query: ({ generationId, pagination }) => ({
          url: `api/generation-tasks/${generationId}/logs`,
          method: 'GET',
          params: {
            ...pagination,
          },
        }),
        serializeQueryArgs: ({ endpointName, queryArgs }) => ({
          endpointName,
          generationId: queryArgs.generationId,
        }),
        merge: (currentCache, newCache, args) => {
          if (args.arg.pagination?.skip !== 0) {
            currentCache.data.push(...newCache.data);
            return currentCache;
          }
          return newCache;
        },
        forceRefetch: ({ currentArg, previousArg }) =>
          !isEqual(currentArg, previousArg),
      }
    ),
    getGenerationTaskById: builder.query<TGenerationTask, string>({
      query: (taskId) => ({
        url: `api/generation-tasks/${taskId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useCancelGenerationTaskMutation,
  useGetProjectGenerationTasksQuery,
  useGetTaskLogsQuery,
  useGetGenerationTaskByIdQuery,
} = generationTasksApi;
