import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

import { Mutex } from 'async-mutex';

import { RootState } from '../state';
import {
  logout,
  selectAuth,
  setAuthAction,
} from '../state/features/user/auth.slice';
import { AuthResponse } from './auth/types';

const BASE_URL = process.env.API_HOST;

export const FILE_UPLOAD_API = `${BASE_URL}api/files/upload`;

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = selectAuth(getState() as RootState);
    if (token?.accessToken) {
      headers.set('Authorization', `Bearer ${token.accessToken}`);
    }
    return headers;
  },
});

const mutex = new Mutex();

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      const authData = selectAuth(api.getState() as RootState);

      if (!authData?.refreshToken) {
        api.dispatch(logout());
      }

      try {
        const refreshResult = await baseQuery(
          {
            url: `api/auth/refresh-token`,
            method: 'POST',
            body: {
              refresh: authData.refreshToken,
            },
          },
          api,
          extraOptions
        );

        if (refreshResult.data) {
          api.dispatch(setAuthAction(refreshResult.data as AuthResponse));
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(logout());
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

export const baseEmptyApi = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});
