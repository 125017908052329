import {
  CacheProvider,
  ThemeProvider as EmotionThemeProvider,
  Theme,
} from '@emotion/react';
import { FC, PropsWithChildren, useMemo } from 'react';

import createCache from '@emotion/cache';
import { App, ConfigProvider } from 'antd';

import { useThemeSelector } from '../../state/features/app/selectors';
import GlobalStyles from '../../styles/global-styles';
import { THEME_LIGHT } from '../../styles/theme';

import locale from 'antd/locale/ru_RU';

const appCache = createCache({
  key: 'banner-gen-application',
  prepend: true,
  speedy: false,
});

export const useCurrentTheme: () => Theme = () => {
  const { currentTheme } = useThemeSelector();

  return useMemo(
    () => ({
      MAIN: THEME_LIGHT,
    }),
    [currentTheme]
  );
};

const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const theme = useCurrentTheme();

  return (
    <CacheProvider value={appCache}>
      <EmotionThemeProvider theme={theme}>
        <ConfigProvider
          locale={locale}
          theme={{
            token: {
              colorPrimaryBg: theme.MAIN.ACCENT.C300,
              colorPrimaryBgHover: theme.MAIN.ACCENT.C200,
              colorPrimary: theme.MAIN.ACCENT.C100,
              colorPrimaryHover: theme.MAIN.ACCENT.C200,
              colorError: theme.MAIN.DANGER.C100,
              colorTextBase: theme.MAIN.TEXT.C200,
              colorBgContainer: theme.MAIN.WHITE.C200,
              colorLink: theme.MAIN.ACCENT.C100,
              borderRadius: 4,
              fontFamily: 'Manrope',
              linkDecoration: 'underline',
            },
            components: {
              Pagination: {
                linkDecoration: 'none',
              },
            },
          }}
        >
          <GlobalStyles />
          <App>{children}</App>
        </ConfigProvider>
      </EmotionThemeProvider>
    </CacheProvider>
  );
};

export default ThemeProvider;
