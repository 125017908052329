import { FC } from 'react';

import { TBaseIconProps } from '../../types/ui';

export const CreatorLogo: FC<TBaseIconProps> = (props) => (
  <svg
    width="555"
    height="177"
    viewBox="0 0 555 177"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M479.865 70.3714V87.1196H493.098C497.509 87.1196 500.128 86.706 501.989 85.6033C503.85 84.5005 505.573 82.3639 505.573 78.5043C505.573 74.6446 503.505 72.7837 502.402 72.0256C501.024 71.1296 498.612 70.3714 493.167 70.3714H479.865ZM471.318 63.7549H494.89C498.612 63.7549 504.952 63.7549 509.57 67.2699C513.775 70.5782 514.671 74.7825 514.671 78.711C514.671 81.9504 514.188 86.0168 511.087 89.2562C506.882 93.4604 499.645 93.7361 494.063 93.7361H479.865V112.001H471.318V63.7549Z"
      fill="#3F444F"
    />
    <path
      d="M434.813 68.8547C429.506 68.8547 424.681 70.5777 421.58 73.6103C418.892 76.2983 416.342 80.985 416.342 87.8773C416.342 94.7695 418.892 99.4562 421.58 102.144C424.681 105.177 429.506 106.9 434.813 106.9C440.12 106.9 444.945 105.177 448.046 102.144C450.734 99.4562 453.284 94.7695 453.284 87.8773C453.284 80.985 450.734 76.2983 448.046 73.6103C444.945 70.5777 440.12 68.8547 434.813 68.8547ZM462.382 87.8773C462.382 93.6667 461.21 100.628 455.145 106.348C448.873 112.207 441.154 113.379 434.813 113.379C428.403 113.379 420.684 112.207 414.481 106.348C408.416 100.628 407.244 93.6667 407.244 87.8773C407.244 82.0878 408.416 75.1266 414.481 69.4061C420.684 63.5477 428.403 62.376 434.813 62.376C441.154 62.376 448.873 63.5477 455.145 69.4061C461.21 75.1266 462.382 82.0878 462.382 87.8773Z"
      fill="#3F444F"
    />
    <path
      d="M379.68 70.3714H361.76V63.7549H406.077V70.3714H388.226V112.001H379.68V70.3714Z"
      fill="#3F444F"
    />
    <path
      d="M331.578 93.5293H350.325L340.882 71.681L331.578 93.5293ZM329.028 99.7324L323.652 112.001H314.416L336.402 63.7549H345.5L367.417 112.001H358.182L352.599 99.7324H329.028Z"
      fill="#3F444F"
    />
    <path
      d="M280.094 84.0181H308.835V90.6346H280.094V105.384H313.521V112.001H271.686V63.7549H312.625V70.3714H280.094V84.0181Z"
      fill="#3F444F"
    />
    <path
      d="M228.81 70.3714V87.1196H242.043C246.454 87.1196 249.073 86.706 250.934 85.6033C252.795 84.5005 254.518 82.3639 254.518 78.5043C254.518 74.6446 252.45 72.7837 251.348 72.0256C249.969 71.1296 247.557 70.3714 242.112 70.3714H228.81ZM220.264 63.7549H243.835C247.557 63.7549 253.898 63.7549 258.516 67.2699C262.72 70.5782 263.616 74.7825 263.616 78.711C263.616 81.9504 263.133 86.0168 260.032 89.2562C255.828 93.4604 248.591 93.7361 243.008 93.7361H228.81V112.001H220.264V63.7549Z"
      fill="#3F444F"
    />
    <path
      d="M179.406 85.1897L202.977 63.7549H213.591L188.297 86.2236L214.694 112.001H203.46L179.406 87.3263V112.001H170.859V63.7549H179.406V85.1897Z"
      fill="#3F444F"
    />
    <path
      d="M40 80.4641C40 64.7475 40 56.8891 43.5847 51.1412C45.4944 48.0791 48.0791 45.4944 51.1412 43.5847C56.8891 40 64.7475 40 80.4642 40H96.4869C112.204 40 120.062 40 125.81 43.5847C128.872 45.4944 131.457 48.0791 133.366 51.1412C136.951 56.8891 136.951 64.7475 136.951 80.4642V96.4869C136.951 112.204 136.951 120.062 133.366 125.81C131.457 128.872 128.872 131.457 125.81 133.366C120.062 136.951 112.204 136.951 96.4868 136.951H80.4641C64.7475 136.951 56.8891 136.951 51.1412 133.366C48.0791 131.457 45.4944 128.872 43.5847 125.81C40 120.062 40 112.204 40 96.4868V80.4641Z"
      fill="#9C162E"
    />
    <path
      d="M83.6542 69.7412H92.1684L71.0242 107.011H62.51L83.6542 69.7412Z"
      fill="white"
    />
    <path
      d="M95.508 69.7412H86.9938L108.138 107.011H116.652L95.508 69.7412Z"
      fill="white"
    />
    <path
      d="M58.1015 89.8533H99.3324L104.809 93.6986H55.4121L58.1015 89.8533Z"
      fill="white"
    />
  </svg>
);
