import { FC } from 'react';
import { ProjectStatisticCard } from '../../components/ui/project-statistic/project-statistic-card';
import { ESubPaths, TSubPathsParams } from '../../routes/route-paths';
import { useParams } from 'react-router-dom';
import { usePagination } from '../../hooks/use-pagination';
import { useGetStatisticQuery } from '../../api/projects';
import { DEFAULT_BANNERS_PAGE_SIZE } from '../../context/providers/banners-pagination-provider';
import { Empty, Pagination } from 'antd';
import styled from '@emotion/styled';
import { LoadingOutlined } from '@ant-design/icons';

type Props = {
  projectId?: string;
};

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 320px;
  min-height: 200px;
  font-size: 34px;
`;

const PaginationWrapper = styled.div`
  margin-top: 20px;
`;

const StyledEmpty = styled(Empty)`
  margin-block: 50px;
`;

const DEFAULT_STATISTIC_PAGE_SIZE = 20;

export const ProjectStatisticTab: FC<Props> = ({projectId}) => {
  const { pagination, onPageChange, currentPage } = usePagination(DEFAULT_STATISTIC_PAGE_SIZE)
  const { generationId } = useParams<TSubPathsParams<ESubPaths.STATISTIC>>();

  const { data, isLoading } = useGetStatisticQuery(
    { projectId, pagination, generationId },
    {
      skip: !(projectId || generationId),
    }
  );

  return (
    <div>
      {isLoading && (
        <LoadingWrapper>
          <LoadingOutlined />
        </LoadingWrapper>
      )}
      {data?.banners?.map((banner) => (
          <ProjectStatisticCard
            key={banner.id}
            info={banner}
            statistic={banner.statistic}
          />
        ))}
      {data?.banners?.length === 0 && (
        <StyledEmpty
          description={
            generationId
              ? 'Баннеры в генерации отсутствуют'
              : 'Баннеры отсутствуют'
          }
          image={Empty.PRESENTED_IMAGE_DEFAULT}
        />
      )}
      {((data?.total ?? 0) > pagination.take ||
        DEFAULT_BANNERS_PAGE_SIZE !== pagination.take) && (
        <PaginationWrapper>
          <Pagination
            total={data?.total}
            pageSize={pagination.take}
            onChange={onPageChange}
            current={currentPage}
          />
        </PaginationWrapper>
      )}
    </div>
  )
};
