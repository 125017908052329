import {
  FC,
  ReactNode,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  DeleteOutlined,
  EditOutlined,
  HomeOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Button, Popconfirm, Space, Tabs, TabsProps } from 'antd';
import dayjs from 'dayjs';

import { useGetProjectGenerationTasksQuery } from '../../api/generation-tasks';
import { useGetByIdQuery } from '../../api/projects';
import AppSubjectPageLayout from '../../components/layouts/app-base-layout/app-subject-page-layout';
import ProjectPageHeaderSection from '../../components/ui/project-page-header-section';
import { BannersPaginationProvider } from '../../context/providers/banners-pagination-provider';
import { useDeleteProject } from '../../hooks/projects/use-delete-project';
import { ProjectRoutes } from '../../routes/project-routes';
import { EPaths, ESubPaths, TPathsParams } from '../../routes/route-paths';
import { EGenerationTaskStatus, TCurrentFullStatus } from '../../types/task';
import { CreateProjectModal } from '../modals/create-project-modal';
import { ProjectBannersButtons } from '../project/project-banners-buttons';

import { BreadcrumbProps } from 'antd/es/breadcrumb/Breadcrumb';

enum EProjectTab {
  PROJECT = 'project',
  GENERATION_TASKS = 'generationTasks',
  BANNERS = 'banners',
  STATISTIC = 'statistic',
  LOGS = 'logs',
  TEMPLATE = 'template',
}

const projectTabs: TabsProps['items'] = [
  {
    key: EProjectTab.PROJECT,
    label: 'Основная информация',
  },
  {
    key: EProjectTab.GENERATION_TASKS,
    label: 'Генерации',
  },
  {
    key: EProjectTab.BANNERS,
    label: 'Баннеры',
  },
  {
    key: EProjectTab.STATISTIC,
    label: 'Статистика',
  },
  {
    key: EProjectTab.LOGS,
    label: 'Логи',
  },
  {
    key: EProjectTab.TEMPLATE,
    label: 'Шаблон',
  },
];

const ProjectPage: FC = () => {
  const { projectId } = useParams<TPathsParams<EPaths.HOME>>();
  const navigate = useNavigate();
  const location = useLocation();
  const [createProjectModalOpen, setCreateProjectModalOpen] = useState(false);

  const [lastTaskIsQueued, setLastTaskIsQueued] = useState(false);

  const { data: projectTaskData, isFetching: tasksIsLoading } =
    useGetProjectGenerationTasksQuery(projectId as string, {
      skip: !projectId,
      pollingInterval: lastTaskIsQueued ? 5000 : 0,
      skipPollingIfUnfocused: true,
    });

  const projectTaskFullStatus: TCurrentFullStatus = useMemo(() => {
    let result: TCurrentFullStatus = {
      lastStatus: 'empty',
      lastTaskUpdateDate: dayjs().toISOString(),
      isLoading: tasksIsLoading,
    };

    if (
      projectTaskData?.generationTasks &&
      Array.isArray(projectTaskData.generationTasks) &&
      projectTaskData.generationTasks.length > 0
    ) {
      const lastTask = [...projectTaskData.generationTasks]?.sort((a, b) =>
        b.createdAt && a.createdAt
          ? dayjs(a.createdAt).diff(dayjs(b.createdAt), 'hours')
          : 0
      )[0];

      if (lastTask) {
        result = {
          ...result,
          lastStatus: lastTask.status,
          lastTaskUpdateDate: lastTask.updatedAt,
          lastId: lastTask.id,
        };

        setLastTaskIsQueued(lastTask.status === EGenerationTaskStatus.QUEUED);
      }
    }

    return result;
  }, [projectTaskData, tasksIsLoading]);

  const [bannerActiveTab, setBannerActivePage] = useState<EProjectTab>(
    EProjectTab.PROJECT
  );

  const onBannerTabKeyChange = useCallback((key: string) => {
    let path: string;

    switch (key as EProjectTab) {
      case EProjectTab.PROJECT:
        path = '';
        break;
      case EProjectTab.GENERATION_TASKS:
        path = ESubPaths.GENERATION_TASKS;
        break;
      case EProjectTab.BANNERS:
        path = ESubPaths.BANNERS;
        break;
      case EProjectTab.LOGS:
        path = ESubPaths.LOGS;
        break;
      case EProjectTab.TEMPLATE:
        path = ESubPaths.TEMPLATE;
        break;
      case EProjectTab.STATISTIC:
        path = ESubPaths.STATISTIC;
        break;
      default:
        path = '';
    }

    navigate(path, {
      relative: 'route',
      replace: false,
    });

    setBannerActivePage(key as EProjectTab);
  }, []);

  useLayoutEffect(() => {
    if (location.pathname.endsWith(ESubPaths.TEMPLATE)) {
      setBannerActivePage(EProjectTab.TEMPLATE);
    } else if (location.pathname.endsWith(ESubPaths.GENERATION_TASKS)) {
      setBannerActivePage(EProjectTab.GENERATION_TASKS);
    } else if (location.pathname.includes(`/${ESubPaths.BANNERS}`)) {
      setBannerActivePage(EProjectTab.BANNERS);
    } else if (location.pathname.includes(`/${ESubPaths.LOGS}`)) {
      setBannerActivePage(EProjectTab.LOGS);
    } else if (location.pathname.endsWith(`/${ESubPaths.STATISTIC}`)) {
      setBannerActivePage(EProjectTab.STATISTIC);
    } else {
      setBannerActivePage(EProjectTab.PROJECT);
    }
  }, [location]);

  const { isLoading, data } = useGetByIdQuery(projectId as string, {
    skip: !projectId,
  });

  const afterDeleteCallback = useCallback(() => {
    navigate(EPaths.HOME);
  }, []);

  const handleDeleteProject = useDeleteProject(afterDeleteCallback);

  const handleStartEdit = () => {
    setCreateProjectModalOpen(true);
  };

  const pageBreadCrumbs: BreadcrumbProps['items'] = useMemo(
    () => [
      {
        title: (
          <NavLink to={EPaths.HOME}>
            <Space>
              <HomeOutlined />
              Проекты
            </Space>
          </NavLink>
        ),
      },
      {
        title: isLoading ? (
          <>
            <LoadingOutlined />
            Загрузка
          </>
        ) : (
          data?.name
        ),
      },
    ],
    [isLoading, data]
  );

  const pageExtraButtons: ReactNode = useMemo(
    () => (
      <>
        <Button icon={<EditOutlined />} onClick={handleStartEdit}>
          Редактировать проект
        </Button>
        <Popconfirm
          title="Вы уверены что хотите удалить проект?"
          okButtonProps={{
            danger: true,
          }}
          okText="Удалить"
          placement="bottomRight"
          onConfirm={() => {
            void handleDeleteProject(projectId);
          }}
        >
          <Button icon={<DeleteOutlined />} danger type="primary">
            Удалить проект
          </Button>
        </Popconfirm>
      </>
    ),
    [handleDeleteProject, projectId]
  );

  return (
    <BannersPaginationProvider>
      <AppSubjectPageLayout
        breadcrumbList={pageBreadCrumbs}
        extraButtons={pageExtraButtons}
      >
        <ProjectPageHeaderSection projectName={data?.name} />
        <ProjectBannersButtons
          projectId={projectId}
          projectTaskFullStatus={projectTaskFullStatus}
          scheduledFeedURL={data?.scheduledFeedURL}
        />
        <Tabs
          activeKey={bannerActiveTab}
          onChange={onBannerTabKeyChange}
          items={projectTabs}
        />
        <ProjectRoutes projectId={projectId} />
        <CreateProjectModal
          open={createProjectModalOpen}
          setOpen={setCreateProjectModalOpen}
          existProjectId={projectId}
        />
      </AppSubjectPageLayout>
    </BannersPaginationProvider>
  );
};

export default ProjectPage;
