import { FC, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { LogoutOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Button, Tooltip } from 'antd';

import { APP_BASE_MENU } from '../../../constants/menus';
import { EPathKeyParams, EPaths } from '../../../routes/route-paths';
import { useAppDispatch } from '../../../state';
import { logout } from '../../../state/features/user/auth.slice';
import { ECSSVars } from '../../../styles/global-styles';
import { CreatorLogo } from '../../icons/creator-logo';
import { BaseMenuItem, TMenuItem } from './base-menu-item';

const Wrapper = styled.aside`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  height: 100vh;
  // Объявлена в ./index.tsx
  width: var(${ECSSVars.APP_MENU_WIDTH});
  background-color: ${({ theme }) => theme.MAIN.WHITE.C200};
`;

const LogoWrapper = styled.div`
  width: 180px;

  svg {
    width: 100%;
    height: auto;
  }
`;

const Header = styled.header`
  padding: 30px 12px;
  height: 120px;
  display: flex;
  align-items: center;
`;

const Menu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ButtonsWrapper = styled.div`
  margin-top: auto;
  padding: 20px;
`;

export const AppBaseMenu: FC = () => {
  const location = useLocation();
  const params = useParams();
  const dispatch = useAppDispatch();

  const appMenuList: TMenuItem[] = useMemo(
    () =>
      APP_BASE_MENU.map((el) => {
        let isActive =
          el.to === EPaths.HOME
            ? el.to === location.pathname
            : location.pathname.startsWith(el.to);

        if (
          Object.prototype.hasOwnProperty.call(
            params,
            EPathKeyParams.PROJECT_ID
          ) &&
          el.to === EPaths.HOME
        ) {
          isActive = true;
        }

        return {
          ...el,
          active: isActive,
        };
      }),
    [location]
  );

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <Wrapper>
      <Header>
        <LogoWrapper>
          <CreatorLogo />
        </LogoWrapper>
      </Header>
      <Menu>
        {appMenuList.map((el) => (
          <BaseMenuItem key={el.key} menuItem={el} />
        ))}
      </Menu>
      <ButtonsWrapper>
        <Tooltip title="Выйти из аккаунта" placement="right">
          <Button
            icon={<LogoutOutlined />}
            size="large"
            onClick={handleLogout}
          />
        </Tooltip>
      </ButtonsWrapper>
    </Wrapper>
  );
};
