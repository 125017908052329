import { Global, Theme, css, withTheme } from '@emotion/react';
import { FC } from 'react';

import ManropeBold from '../assets/fonts/Manrope-Bold.ttf';
import ManropeRegular from '../assets/fonts/Manrope-Regular.ttf';
import PragmaticaOtf from '../assets/fonts/pragmatica_book.otf';
import PragmaticaWoff from '../assets/fonts/pragmatica_book.woff';
import PragmaticaWoff2 from '../assets/fonts/pragmatica_book.woff2';
import { APP_ROOT_ID } from '../constants';

export enum ECSSVars {
  APP_MENU_WIDTH = '--menu-width',
}

type TProps = {
  theme: Theme;
};

const GlobalStyles: FC<TProps> = ({ theme }) => (
  <Global
    styles={css`
      @font-face {
        font-family: 'Manrope';
        src: url('${ManropeRegular}');
        font-weight: normal;
        font-style: normal;
      }

      @font-face {
        font-family: 'Manrope';
        src: url('${ManropeBold}');
        font-weight: bold;
        font-style: normal;
      }

      @font-face {
        font-family: 'Pragmatica';
        src:
          url('${PragmaticaOtf}') format('otf'),
          url('${PragmaticaWoff}') format('woff'),
          url('${PragmaticaWoff2}') format('woff2');
        font-weight: normal;
        font-style: normal;
      }

      :root {
        ${ECSSVars.APP_MENU_WIDTH}: 320px;
      }

      html {
        background-color: ${theme.MAIN.WHITE.C100};
        color: ${theme.MAIN.TEXT.C100};
        font-family: 'Manrope', serif;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: 'Pragmatica', serif !important;
      }

      *,
      *::after,
      *::before {
        box-sizing: border-box;
      }

      html,
      body {
        padding: 0;
        margin: 0;
      }

      #${APP_ROOT_ID} {
        height: 100vh;
        width: 100vw;

        &::-webkit-scrollbar {
          background: transparent;
          width: 10px;
          max-width: 10px;
          height: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background: ${theme.MAIN.BLACK.C300};
          border-radius: 5px;
          width: 10px;
          height: 10px;
        }
      }
    `}
  />
);

export default withTheme(GlobalStyles);
