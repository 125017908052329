import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

import { LoadingOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Empty } from 'antd';
import dayjs from 'dayjs';

import {
  useGetProjectGenerationTasksQuery,
  useGetTaskLogsQuery,
} from '../../api/generation-tasks';
import { BackToMainPane } from '../../components/ui/back-to-main-pane';
import { useInfinityScroll } from '../../hooks/use-infinity-scroll';
import { ESubPaths, TSubPathsParams } from '../../routes/route-paths';
import { EGenerationTaskStatus } from '../../types/task';

type TProps = {
  projectId?: string;
  hideBackButton?: boolean;
};

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  font-size: 34px;
`;

const DEFAULT_LOGS_LENGTH = 20;

export const ProjectGenerationLogsTab: FC<TProps> = ({
  projectId,
  hideBackButton = false,
}) => {
  const navigate = useNavigate();
  const [dataOffset, setDataOffset] = useState(0);

  const { data: projectTaskData } = useGetProjectGenerationTasksQuery(
    projectId as string,
    {
      skip: !projectId,
    }
  );

  const { generationId } = useParams<TSubPathsParams<ESubPaths.BANNERS>>();

  const lastTask = useMemo(
    () =>
      projectTaskData?.generationTasks?.[0]
        ? projectTaskData?.generationTasks[0]
        : undefined,
    [projectTaskData]
  );

  const logsId: string | undefined = useMemo(
    () => generationId ?? lastTask?.id,
    [generationId, lastTask]
  );

  const currentTask = useMemo(
    () =>
      projectTaskData
        ? projectTaskData.generationTasks?.find?.((el) => el.id === logsId)
        : undefined,
    [projectTaskData, logsId]
  );

  const onInfinityScroll = useCallback(() => {
    setDataOffset((prev) => prev + DEFAULT_LOGS_LENGTH);
  }, []);

  const {
    data: taskLogsData,
    isLoading,
    isFetching,
    error,
  } = useGetTaskLogsQuery(
    {
      generationId: logsId as string,
      pagination: {
        take: DEFAULT_LOGS_LENGTH,
        skip: dataOffset,
      },
    },
    {
      skip: !logsId,
      pollingInterval: ![
        EGenerationTaskStatus.DONE,
        EGenerationTaskStatus.ERROR,
        EGenerationTaskStatus.CANCELLED,
      ].includes(currentTask?.status as EGenerationTaskStatus)
        ? 5000
        : undefined,
    }
  );

  const shouldSkipFetching: boolean = useMemo(
    () =>
      taskLogsData
        ? taskLogsData.total <= dataOffset + DEFAULT_LOGS_LENGTH
        : true,
    [taskLogsData, dataOffset]
  );

  const { triggerRef } = useInfinityScroll(
    isFetching,
    onInfinityScroll,
    shouldSkipFetching
  );

  const logsText: string = useMemo(
    () =>
      taskLogsData?.data
        ? taskLogsData.data
            .map((el) => {
              const log = `${dayjs(el.updatedAt).format('DD MMMM YYYY HH:mm:ss:ms')} - ${el.message}`;

              let additionalParamsLogs = '';

              Object.keys(el.data).forEach((key) => {
                if (key !== 'message') {
                  additionalParamsLogs += `${key} - ${JSON.stringify(el.data[key])}\n`;
                }
              });

              return `${log}\n${additionalParamsLogs}\n`;
            })
            .join('\n')
        : 'No any logs',
    [taskLogsData]
  );

  const goBack = useCallback(() => {
    navigate(
      generationId
        ? `../../${ESubPaths.GENERATION_TASKS}`
        : `../${ESubPaths.GENERATION_TASKS}`,
      { relative: 'path' }
    );
  }, [generationId]);

  return (
    <div>
      {!hideBackButton && (
        <BackToMainPane
          title={generationId ? 'Логи генерации' : 'Логи последней генерации'}
          backTooltip="Показать все генерации"
          onGoBack={goBack}
        />
      )}
      {!(generationId || lastTask?.id) && (
        <Empty
          image={Empty.PRESENTED_IMAGE_DEFAULT}
          description="Нет доступных генераций для просмотра логов"
        />
      )}
      {isLoading && (
        <LoadingWrapper>
          <LoadingOutlined />
        </LoadingWrapper>
      )}
      <SyntaxHighlighter language="text" style={a11yDark} showLineNumbers>
        {logsText}
      </SyntaxHighlighter>
      {!(shouldSkipFetching || error) && <div ref={triggerRef} />}
    </div>
  );
};
