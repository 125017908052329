import { FC } from 'react';

import {
  FinanceHeader,
  FinancePlan,
  FinancePlansGrid,
} from '../../components/ui/finance';
import { EFinanceHeadColor } from '../../components/ui/finance/plan';
import { EFinancePlanType } from '../../types/finance';

export const FinancePage: FC = () => (
  <div>
    <FinanceHeader />
    <FinancePlansGrid>
      <FinancePlan
        planName="Проба пера"
        isCurrent
        features={{
          type: EFinancePlanType.TESTING,
          skuLimit: 10000,
          updatesPerDay: 1,
          price: 99000,
        }}
      />
      <FinancePlan
        planName="Дизайнер"
        color={EFinanceHeadColor.CYAN}
        features={{
          type: EFinancePlanType.DESIGNER,
          skuLimit: 150000,
          updatesPerDay: 1,
          price: 299000,
          connectionPrice: 149000,
        }}
      />
      <FinancePlan
        planName="Корпоративный"
        color={EFinanceHeadColor.BLACK}
        features={{
          type: EFinancePlanType.CORP,
          skuLimit: 150000,
          updatesPerDay: 1,
        }}
      />
    </FinancePlansGrid>
  </div>
);
