import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import styled from '@emotion/styled';

import { CreatorLogo } from '../../icons/creator-logo';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
`;

const Inner = styled.div`
  min-width: 320px;
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  svg {
    width: 180px;
    height: auto;
  }
`;

const AppFormLayout: FC = () => (
  <Wrapper>
    <Inner>
      <LogoWrapper>
        <CreatorLogo />
      </LogoWrapper>
      <Outlet />
    </Inner>
  </Wrapper>
);

export default AppFormLayout;
