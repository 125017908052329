import { FC } from 'react';

import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Image, Statistic } from 'antd';
import dayjs from 'dayjs';

import { TStatistic, TStatisticBanners } from '../../../api/projects/types';

type TProps = {
  info: TStatisticBanners;
  statistic: TStatistic;
};

const Wrapper = styled.div`
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 32px;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.MAIN.WHITE.C500};
  }
`;

const ImgContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 130px;
  max-height: 130px;
`;

const StatWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`;

export const ProjectStatisticCard: FC<TProps> = ({ info, statistic }) => (
  <Wrapper>
    <ImgContainer>
      <Image src={info.imageURL} />
    </ImgContainer>
    <StatWrapper>
      <Statistic
        title="Динамика за неделю"
        value={statistic.weekGrowthRate}
        valueStyle={{
          color: statistic.weekGrowthRate >= 0 ? '#3f8600' : '#cf1322',
        }}
        prefix={
          statistic.weekGrowthRate >= 0 ? (
            <ArrowUpOutlined />
          ) : (
            <ArrowDownOutlined />
          )
        }
        suffix="%"
      />
      <Statistic
        title="Динамика за месяц"
        value={statistic.monthGrowthRate}
        valueStyle={{
          color: statistic.monthGrowthRate >= 0 ? '#3f8600' : '#cf1322',
        }}
        prefix={
          statistic.monthGrowthRate >= 0 ? (
            <ArrowUpOutlined />
          ) : (
            <ArrowDownOutlined />
          )
        }
        suffix="%"
      />
      <Statistic
        title="Всего просмотров"
        value={statistic.totalDownloads}
        formatter="number"
      />
      <Statistic
        title="Просмотров за неделю"
        value={statistic.currentWeekDownloads}
        formatter="number"
      />
      <Statistic
        title="Дата создания"
        value={dayjs(info.createdAt).format('DD.mm.YYYY HH:MM')}
      />
    </StatWrapper>
  </Wrapper>
);
