import { NavLink } from 'react-router-dom';

import {
  CodeOutlined,
  FileImageOutlined,
  StopOutlined,
} from '@ant-design/icons';
import styled from '@emotion/styled';
import { Button, Flex, Tooltip } from 'antd';
import dayjs from 'dayjs';

import { IconTableCell } from '../../components/tables/icon-table-cell';
import { EPaths, ESubPaths } from '../../routes/route-paths';
import { THEME_LIGHT } from '../../styles/theme';
import {
  EGenerationTaskStatus,
  EGenerationTaskStatusWithEmpty,
  ICON_BY_STATUS,
  TEXT_BY_GENERATION_STATUS,
  TGenerationSettings,
} from '../../types/task';
import { TProjectTasksColumnsData } from '../project/project-generation-tasks-tab';

import { ColumnsType } from 'antd/lib/table';

type TProps = {
  stopGenerationTask: (id: string) => void;
};

const AlertTypeByStatus: Record<EGenerationTaskStatusWithEmpty, string> = {
  [EGenerationTaskStatus.QUEUED]: THEME_LIGHT.ACCENT.C100,
  [EGenerationTaskStatus.CREATED]: THEME_LIGHT.WARNING.C100,
  [EGenerationTaskStatus.DONE]: THEME_LIGHT.SUCCESS.C100,
  [EGenerationTaskStatus.ERROR]: THEME_LIGHT.DANGER.C100,
  [EGenerationTaskStatus.CANCELLED]: THEME_LIGHT.BLACK.C300,
  empty: THEME_LIGHT.ACCENT.C300,
};

const StatusWrapper = styled.div<{ status: EGenerationTaskStatusWithEmpty }>`
  color: ${({ status }) => AlertTypeByStatus[status]};
`;

export const getProjectGenerationTableColumns: (
  props: TProps
) => ColumnsType<TProjectTasksColumnsData> = ({ stopGenerationTask }) => [
  {
    key: 'index',
    dataIndex: 'index',
    title: '№',
    width: 50,
    render: (index, dataSource) => (
      <NavLink
        to={`${EPaths.GENERATION_TASK}/${dataSource.key}/${dataSource.projectId}`}
      >
        {index}
      </NavLink>
    ),
  },
  {
    key: 'status',
    dataIndex: 'status',
    render: (value: EGenerationTaskStatus) => (
      <Tooltip
        title={TEXT_BY_GENERATION_STATUS[value].title}
        placement="bottom"
      >
        <StatusWrapper status={value}>
          <IconTableCell value={ICON_BY_STATUS[value]} />
        </StatusWrapper>
      </Tooltip>
    ),
  },
  {
    key: 'settings',
    dataIndex: 'settings',
    title: 'Настройки',
    render: (value: TGenerationSettings) => (
      <Flex wrap="wrap" gap={12}>
        <span>
          SKU от {value.from || 0} {value.to ? `до ${value.to}` : ''}
        </span>
        {value.transactional && <span>Транзакция</span>}
        {value.skipUnavailable && <span>Только доступные</span>}
        {value.checkWidthOverflow && <span>Проверка строк</span>}
      </Flex>
    ),
  },
  {
    key: 'createAt',
    dataIndex: 'createAt',
    title: 'Дата создания',
    render: (date: string) => dayjs(date).format('DD MMMM YYYY HH:mm'),
  },
  {
    key: 'updateAt',
    dataIndex: 'updateAt',
    title: 'Дата обновления',
    render: (date: string) => dayjs(date).format('DD MMMM YYYY HH:mm'),
  },
  {
    key: 'banners',
    dataIndex: 'key',
    render: (key: string) => (
      <Tooltip title="Баннеры" placement="bottom">
        <NavLink to={`../${ESubPaths.BANNERS}/${key}`} relative="route">
          <Button icon={<FileImageOutlined />} type="primary" shape="circle" />
        </NavLink>
      </Tooltip>
    ),
  },
  {
    key: 'logs',
    dataIndex: 'key',
    render: (key: string) => (
      <Tooltip title="Логи" placement="bottom">
        <NavLink to={`../${ESubPaths.LOGS}/${key}`}>
          <Button icon={<CodeOutlined />} shape="circle" />
        </NavLink>
      </Tooltip>
    ),
  },
  {
    key: 'stop',
    dataIndex: 'key',
    title: '',
    render: (key: string, data) => {
      if (data.status !== EGenerationTaskStatus.QUEUED) {
        return null;
      }

      const onClick = () => {
        stopGenerationTask(key);
      };

      return (
        <Button onClick={onClick} size="small" icon={<StopOutlined />} danger>
          Остановить задачу
        </Button>
      );
    },
  },
];
