import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import AppBaseLayout from '../components/layouts/app-base-layout';
import AppFormLayout from '../components/layouts/app-form-layout';
import { FeedsPage } from '../containers/pages/feeds-page';
import { FinancePage } from '../containers/pages/finance-page';
import { FontPage } from '../containers/pages/font-page';
import { GenerationPage } from '../containers/pages/generation-page';
import { HomePage } from '../containers/pages/home-page';
import LoginPage from '../containers/pages/login-page';
import { PluginAuthPage } from '../containers/pages/plugin-auth-page';
import { ProfilePage } from '../containers/pages/profile-page';
import ProjectPage from '../containers/pages/project-page';
import RegisterPage from '../containers/pages/register-page';
import { EPathKeyParams, EPaths } from './route-paths';

export const RootRoutes: FC = () => (
  <Routes>
    <Route element={<AppFormLayout />}>
      <Route path={EPaths.LOGIN} element={<LoginPage />} />
      <Route path={EPaths.REGISTER} element={<RegisterPage />} />
    </Route>
    <Route element={<AppBaseLayout />}>
      <Route path={EPaths.HOME} element={<HomePage />} />
      <Route path={EPaths.FEEDS} element={<FeedsPage />} />
      <Route path={EPaths.FINANCE} element={<FinancePage />} />
      <Route path={EPaths.FONTS} element={<FontPage />} />
      <Route
        path={`/:${EPathKeyParams.PROJECT_ID}/*`}
        element={<ProjectPage />}
      />
      <Route path={EPaths.PLUGIN_AUTH} element={<PluginAuthPage />} />
      <Route path={EPaths.PROFILE} element={<ProfilePage />} />
      <Route
        path={`${EPaths.GENERATION_TASK}/:${EPathKeyParams.GENERATION_ID}/:${EPathKeyParams.PROJECT_ID}/*`}
        element={<GenerationPage />}
      />
    </Route>
  </Routes>
);
