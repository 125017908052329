import { FC, Fragment, memo, useMemo } from 'react';

import styled from '@emotion/styled';
import { Descriptions, DescriptionsProps, Statistic } from 'antd';
import dayjs from 'dayjs';

import { TEXT_BY_GENERATION_STATUS, TGenerationTask } from '../../types/task';

const DescriptionsWrapper = styled.div`
  margin-top: 20px;
`;

const StatisticWrapper = styled.div`
  display: flex;
  gap: 22px;
  flex-wrap: wrap;
`;

type Props = {
  task?: TGenerationTask;
  loading: boolean;
};

export const GenerationStatistic: FC<Props> = memo(({ task, loading }) => {
  const descriptionItems: DescriptionsProps['items'] = useMemo(
    () => [
      {
        key: 'count',
        label: 'Количество SKU',
        children: task?.settings.count ?? 'Не установлено',
      },
      {
        key: 'from',
        label: 'SKU от',
        children: task?.settings.from,
      },
      {
        key: 'to',
        label: 'SKU до',
        children: task?.settings.to ?? 'Не установлено',
      },
      {
        key: 'transactional',
        label: 'Транзакционная генерация',
        children: task?.settings.transactional ? 'Да' : 'Нет',
      },
      {
        key: 'skipUnavailable',
        label: 'Недоступные офферы',
        children: task?.settings.skipUnavailable
          ? 'Пропускать'
          : 'Обрабатывать',
      },
      {
        key: 'checkWidthOverflow',
        label: 'Проверка длины текста',
        children: task?.settings.checkWidthOverflow ? 'Да' : 'Нет',
      },
      {
        key: 'includeKeywords',
        label: 'Включить слова',
        children: task?.settings.includeKeywords ?? 'Не установлено',
      },
      {
        key: 'excludeKeywords',
        label: 'Исключать слова',
        children: task?.settings.excludeKeywords ?? 'Не установлено',
      },
      {
        key: 'priceFrom',
        label: 'Цена от',
        children: task?.settings.priceFrom ?? 'Не установлено',
      },
      {
        key: 'priceTo',
        label: 'Цена до',
        children: task?.settings.priceTo ?? 'Не установлено',
      },
    ],
    [task]
  );

  return (
    <>
      <StatisticWrapper>
        <Statistic
          title="Статус"
          value={TEXT_BY_GENERATION_STATUS[task?.status ?? 'empty'].short}
          loading={loading}
        />
        <Statistic
          title="Дата создания"
          value={dayjs(task?.createdAt).format('DD.MM.YYYY')}
          loading={loading}
        />
        <Statistic
          title="Дата обновления"
          value={dayjs(task?.updatedAt).format('DD.MM.YYYY')}
          loading={loading}
        />
      </StatisticWrapper>
      <DescriptionsWrapper>
        {task?.settings && (
          <Descriptions
            title="Настройки генерации"
            items={descriptionItems}
            bordered
          />
        )}
      </DescriptionsWrapper>
    </>
  );
});
