import { ComponentType, FC } from 'react';
import { NavLink } from 'react-router-dom';

import styled from '@emotion/styled';

import { EPaths } from '../../../routes/route-paths';
import { TTextBool, booleanToText, textToBoolean } from '../../../utils/ui';

const MenuLabel = styled('span')<{
  active: TTextBool;
}>`
  font-size: 1rem;
  line-height: 1rem;
  margin-top: 0.06rem;
  font-weight: bold;
  color: ${({ theme, active }) =>
    textToBoolean(active) ? theme.MAIN.WHITE.C100 : theme.MAIN.TEXT.C200};
  user-select: none;
  transition: color 0.3s ease-out;
`;

const IconWrapper = styled('div')<{
  active: TTextBool;
}>`
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.125rem;
  height: 1.125rem;

  color: ${({ active, theme }) =>
    textToBoolean(active) ? theme.MAIN.WHITE.C100 : theme.MAIN.TEXT.C200};

  svg {
    width: 100%;
    height: 100%;
    transition: color 0.3s ease-out;
  }
`;

const MenuLink = styled(NavLink)<{
  active: TTextBool;
}>`
  display: flex;
  padding: 18px;
  background-color: ${({ theme, active }) =>
    textToBoolean(active) ? theme.MAIN.ACCENT.C100 : theme.MAIN.WHITE.C300};
  transition: background-color 0.3s ease-out;
  text-decoration: none;

  &:hover {
    background-color: ${({ theme, active }) =>
      textToBoolean(active) ? theme.MAIN.ACCENT.C100 : theme.MAIN.WHITE.C300};
  }

  &:focus {
    outline: 2px solid ${({ theme }) => theme.MAIN.ACCENT.C300};
  }
`;

const MenuItem = styled('li')`
  width: 100%;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`;

export type TMenuItem = {
  key: string;
  label: string;
  to: EPaths;
  Icon: ComponentType;
  active: boolean;
};

type TProps = {
  menuItem: TMenuItem;
};

export const BaseMenuItem: FC<TProps> = ({ menuItem }) => (
  <MenuItem
    key={menuItem.key}
    aria-label={menuItem.label}
    aria-selected={menuItem.active}
  >
    <MenuLink to={menuItem.to} active={booleanToText(menuItem.active)}>
      <IconWrapper active={booleanToText(menuItem.active)}>
        <menuItem.Icon />
      </IconWrapper>
      <MenuLabel active={booleanToText(menuItem.active)}>
        {menuItem.label}
      </MenuLabel>
    </MenuLink>
  </MenuItem>
);
