import { FC, useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';

import { HomeOutlined, LoadingOutlined } from '@ant-design/icons';
import { Space, Tabs, TabsProps } from 'antd';
import dayjs from 'dayjs';

import { useGetGenerationTaskByIdQuery } from '../../api/generation-tasks';
import { useGetByIdQuery } from '../../api/projects';
import AppSubjectPageLayout from '../../components/layouts/app-base-layout/app-subject-page-layout';
import { GenerationStatistic } from '../../components/ui/generation-statistic';
import { GenerationRoutes } from '../../routes/generation-routes';
import { EPaths, ESubPaths, TPathsParams } from '../../routes/route-paths';

import { BreadcrumbProps } from 'antd/es/breadcrumb/Breadcrumb';

const generationTabs: TabsProps['items'] = [
  {
    key: ESubPaths.BANNERS,
    label: 'Баннеры',
  },
  {
    key: ESubPaths.STATISTIC,
    label: 'Статистика',
  },
  {
    key: ESubPaths.LOGS,
    label: 'Логи',
  },
];

export const GenerationPage: FC = () => {
  const { projectId, generationId } =
    useParams<TPathsParams<EPaths.GENERATION_TASK>>();
  const navigate = useNavigate();
  const location = useLocation();

  const [bannerActiveTab, setBannerActivePage] = useState<ESubPaths>(
    ESubPaths.BANNERS
  );

  const { data: projectData, isLoading: isProjectLoading } = useGetByIdQuery(
    projectId as string,
    {
      skip: !projectId,
    }
  );

  const { data: taskData, isLoading: isTaskLoading } =
    useGetGenerationTaskByIdQuery(generationId as string, {
      skip: !generationId,
    });

  const pageBreadCrumbs: BreadcrumbProps['items'] = useMemo(
    () => [
      {
        title: (
          <NavLink to={EPaths.HOME}>
            <Space>
              <HomeOutlined />
              Проекты
            </Space>
          </NavLink>
        ),
      },
      {
        // eslint-disable-next-line no-nested-ternary
        title: isProjectLoading ? (
          <>
            <LoadingOutlined />
            Загрузка
          </>
        ) : projectData ? (
          <NavLink to={`${EPaths.HOME}${projectData?.id}`}>
            {projectData?.name}
          </NavLink>
        ) : (
          'Проект не найден'
        ),
      },
      {
        // eslint-disable-next-line no-nested-ternary
        title: isTaskLoading ? (
          <>
            <LoadingOutlined />
            Загрузка
          </>
        ) : taskData ? (
          `Генерация от ${dayjs(taskData.updatedAt).format('DD.MM.YYYY HH:mm')}`
        ) : (
          'Генерация не найдена'
        ),
      },
    ],
    [isProjectLoading, projectData, taskData, isTaskLoading]
  );

  const onBannerTabKeyChange = useCallback((key: string) => {
    const path: string = key === ESubPaths.BANNERS ? '' : key;

    navigate(path, {
      relative: 'route',
      replace: false,
    });

    setBannerActivePage(key as ESubPaths);
  }, []);

  useLayoutEffect(() => {
    if (location.pathname.includes(`/${ESubPaths.BANNERS}`)) {
      setBannerActivePage(ESubPaths.BANNERS);
    } else if (location.pathname.includes(`/${ESubPaths.LOGS}`)) {
      setBannerActivePage(ESubPaths.LOGS);
    } else if (location.pathname.endsWith(`/${ESubPaths.STATISTIC}`)) {
      setBannerActivePage(ESubPaths.STATISTIC);
    } else {
      setBannerActivePage(ESubPaths.BANNERS);
    }
  }, [location]);

  return (
    <AppSubjectPageLayout breadcrumbList={pageBreadCrumbs}>
      <GenerationStatistic task={taskData} loading={isTaskLoading} />
      <Tabs
        activeKey={bannerActiveTab}
        onChange={onBannerTabKeyChange}
        items={generationTabs}
      />
      <GenerationRoutes projectId={projectId} />
    </AppSubjectPageLayout>
  );
};
