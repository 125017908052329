import { FC, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { FileImageOutlined, LoadingOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Button, Empty, Image, Pagination } from 'antd';

import { useGetProjectBannersQuery } from '../../api/projects';
import { BackToMainPane } from '../../components/ui/back-to-main-pane';
import {
  DEFAULT_BANNERS_PAGE_SIZE,
  useBannersPaginationContext,
} from '../../context/providers/banners-pagination-provider';
import { ESubPaths, TSubPathsParams } from '../../routes/route-paths';
import { TTextBool, booleanToText, textToBoolean } from '../../utils/ui';

type TProps = {
  projectId?: string;
  hideBackButton?: boolean;
};

const Wrapper = styled.div``;

const PaginationWrapper = styled.div`
  margin-top: 20px;
`;

const Inner = styled.div<{
  loading: TTextBool;
  isEmpty: TTextBool;
}>`
  display: ${({ loading, isEmpty }) =>
    textToBoolean(loading) || textToBoolean(isEmpty) ? 'flex' : 'grid'};
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  grid-gap: 18px;
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 320px;
  min-height: 200px;
  font-size: 34px;
`;

const ImageCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    object-fit: contain;
  }
`;

const StyledEmpty = styled(Empty)`
  margin-block: 50px;
`;

const ProjectBannersTab: FC<TProps> = ({
  projectId,
  hideBackButton = false,
}) => {
  const { generationId } = useParams<TSubPathsParams<ESubPaths.BANNERS>>();
  const navigate = useNavigate();

  const { pagination, onPageChange, currentPage } =
    useBannersPaginationContext();

  const { data, isLoading } = useGetProjectBannersQuery(
    { projectId, pagination, generationId },
    {
      skip: !(projectId || generationId),
    }
  );

  const goBack = useCallback(() => {
    navigate(`../../${ESubPaths.GENERATION_TASKS}`, { relative: 'path' });
  }, []);

  const toAllBanners = useCallback(() => {
    navigate(`..`, { relative: 'path' });
  }, []);

  return (
    <Wrapper>
      {generationId && !hideBackButton && (
        <BackToMainPane
          title="Баннеры генерации"
          backTooltip="Обратно к генерациям"
          onGoBack={goBack}
          extraContent={
            <Button icon={<FileImageOutlined />} onClick={toAllBanners}>
              Смотреть все баннеры
            </Button>
          }
        />
      )}
      <Inner
        loading={booleanToText(isLoading)}
        isEmpty={booleanToText(data?.data?.length === 0)}
      >
        {isLoading && (
          <LoadingWrapper>
            <LoadingOutlined />
          </LoadingWrapper>
        )}
        {!isLoading &&
          data?.data?.map((banner) => (
            <ImageCard key={banner.id}>
              <Image src={banner.imageURL} />
            </ImageCard>
          ))}
        {data?.data.length === 0 && !isLoading && (
          <StyledEmpty
            description={
              generationId
                ? 'Баннеры в генерации отсутствуют'
                : 'Баннеры отсутствуют'
            }
            image={Empty.PRESENTED_IMAGE_DEFAULT}
          />
        )}
      </Inner>
      {((data?.total ?? 0) > pagination.take ||
        DEFAULT_BANNERS_PAGE_SIZE !== pagination.take) && (
        <PaginationWrapper>
          <Pagination
            total={data?.total}
            pageSize={pagination.take}
            onChange={onPageChange}
            current={currentPage}
          />
        </PaginationWrapper>
      )}
    </Wrapper>
  );
};

export default ProjectBannersTab;
