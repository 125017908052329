import { FC, useMemo } from 'react';

import { Table } from 'antd';

import { useGetProjectGenerationTasksQuery } from '../../api/generation-tasks';
import { useCancelGenerationTask } from '../../hooks/generation-tasks/use-cancel-generation-task';
import { EGenerationTaskStatus, TGenerationSettings } from '../../types/task';
import { getProjectGenerationTableColumns } from '../tables/project-generation-table';

import { ColumnsType } from 'antd/lib/table';

type TProps = {
  projectId?: string;
};

export type TProjectTasksColumnsData = {
  key: string; // ID
  index: number;
  status: EGenerationTaskStatus;
  settings: TGenerationSettings;
  createAt: string;
  updateAt: string;
  projectId: string;
};

const ProjectGenerationTasksTab: FC<TProps> = ({ projectId }) => {
  const { data: projectTaskData, isLoading: tasksIsLoading } =
    useGetProjectGenerationTasksQuery(projectId as string, {
      skip: !projectId,
    });
  const [cancelGenerationTask] = useCancelGenerationTask();

  const columnsData: TProjectTasksColumnsData[] = useMemo(
    () =>
      projectTaskData?.generationTasks?.map((el, index, arr) => ({
        key: el.id,
        status: el.status,
        settings: el.settings,
        createAt: el.createdAt,
        updateAt: el.updatedAt,
        projectId: projectId as string,
        index: arr.length - index,
      })) ?? [],
    [projectTaskData, projectId]
  );

  const columns: ColumnsType<TProjectTasksColumnsData> = useMemo(
    () =>
      getProjectGenerationTableColumns({
        stopGenerationTask: cancelGenerationTask,
      }),
    []
  );

  return (
    <div>
      <Table
        columns={columns}
        dataSource={columnsData}
        loading={tasksIsLoading}
        bordered={false}
        pagination={false}
      />
    </div>
  );
};

export default ProjectGenerationTasksTab;
